import {EditorSDK, PageRef, PageData} from '@wix/platform-editor-sdk'
import {SANTA_MEMBERS_APP_ID} from '../constants'
import {isPlatformisedPagesPanelEnabled} from '../../utils/experiments'

interface BaseParams {
    editorSDK: EditorSDK
}

interface PageParams {
    pageRef: PageRef
}

export async function updatePageData({editorSDK, pageRef, pageData}: BaseParams & PageParams & {pageData: PageData}) {
    await editorSDK.pages.data.update('', {pageRef, data: {...pageData, indexable: false}})
}

export async function rename({editorSDK, pageRef, title}: BaseParams & PageParams & {title: string}) {
    await editorSDK.pages.rename('', {pageRef, title})
}

export async function updatePagePassword({editorSDK, pageRef, plainPassword}: BaseParams & PageParams & {plainPassword: string}) {
    await editorSDK.pages.permissions.updatePagePassword('', {pageRef, plainPassword})
}

export async function removePagePassword({editorSDK, pageRef}: BaseParams & PageParams) {
    await editorSDK.pages.permissions.removePagePassword('', {pageRef})
}

export async function addPage({editorSDK, pageTitle, pageUriSEO}: BaseParams & {pageTitle: string; pageUriSEO: string}) {
    if (await isPlatformisedPagesPanelEnabled()) {
        return editorSDK.pages.add('', {
            title: pageTitle,
            shouldAddMenuItem: true,
            // @ts-ignore // componentType should be optional in platform typings
            definition: {
                data: {
                    hidePage: false,
                    managingAppDefId: SANTA_MEMBERS_APP_ID,
                    pageUriSEO
                }
            }
        })
    }
    return editorSDK.pages.add('', {title: pageTitle})
}

export async function navigateToPageRef({editorSDK, pageRef}: BaseParams & PageParams) {
    // @ts-ignore // Page link should be optional in platform typings
    await editorSDK.pages.navigateTo('', {pageRef})
}

export function getPageData({editorSDK, pageRef}: BaseParams & PageParams) {
    return editorSDK.pages.data.get('', {pageRef})
}

export function getCurrentPage({editorSDK}: BaseParams) {
    return editorSDK.pages.getCurrent('')
}

export function getHomePage({editorSDK}: BaseParams) {
    return editorSDK.pages.getHomePage('')
}

export function remove({editorSDK, pageRef, shouldShowEditorRemovePanel, pageToNavigateAfterRemove}: BaseParams) {
    return editorSDK.document.pages.remove('', {pageRef, shouldShowEditorRemovePanel, pageToNavigateAfterRemove})
}

export function getAllPages({editorSDK}: BaseParams) {
    return editorSDK.document.pages.data.getAll('')
}

export async function getMainMembersPageRef({editorSDK}: BaseParams) {
    const allPages = await editorSDK.document.pages.data.getAll('')
    const mainPage = allPages.find((page) => page.tpaPageId === 'member_info')
    return mainPage && {id: mainPage.id}
}
